import './home.css';
import Wave from '../images/wave.gif';

function Home() {
  return (
    <div className="Home">
    	<div className="w3-row-padding w3-padding-64 w3-container">
    		<div className="w3-twothird">
      			<h1>Welcome!</h1>
      			
      			<h5 className="w3-padding-32">As you may have guessed, my name is Tyler Williams and this is my website.</h5>
      			<p>I have many varied interests, and I use this space to showcase things I enjoy and create.</p>
      		</div>
		</div>

		<div className="w3-center">
            <img src={Wave} className="wave" alt="Hello" />
        </div>
        
	</div>
  );
}

export default Home;