import './footer.css'
import Mail from '../images/mail.png';
import Link from '../images/linkedin.png';
import Logo from '../images/logo.png';
import Octo from '../images/octo.png';

function Contact() {
	return (
		<div>
		<footer className="w3-container w3-padding-64 w3-center w3-large footer"> 
		<a href="/"><img src={Logo} className="footerMail" alt="Logo" /></a>
		<a href="https://github.com/tylerdamonwill"><img src={Octo} className="footerMail" alt="Octo" /></a>
		<a href="mailto:tylerdamonwill@gmail.com?subject=Hi Tyler!&body=I love your website!"><img alt="link" src={Mail} className="footerMail" /></a>
		<a href="https://www.linkedin.com/in/tyler-williams-35519611b/"><img src={Link} className="footerIMG" alt="link" /></a>
		</footer>
		</div>
	);
}

export default Contact;