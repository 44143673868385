import React from "react";
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';

const App = () => (
  <div>
    <Nav/>
   <Footer />
  </div>
);

export default App;