import Wave from '../images/wave.gif';

function Home() {
  return (
    <div className="Home">
      <div className="w3-row-padding w3-padding-64 w3-container">
        <div className="w3-twothird">
            <h1>Diversifying my portfolio!</h1>
            <p className="w3-text-grey">Wheather its trading stocks, maxing out your 401k, loopholing your way out of paying taxes, or running a small business, I'll share tricks that've help me increase my income and stability.</p>
          </div>

            <div className="w3-center">
            <img src={Wave} className="wave" alt="Hello" />
        </div>
    </div>
  </div>
  );
}

export default Home;