import React from "react";
import Home from '../home/home';
import Professional from '../professional/professional';
import Blog from '../blog/blog';
import Side from '../sideVentures/sideVentures';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './nav.css'

//const mystyle = {
  //backgroundColor: "#CF9762",
  //borderStyle: "none",
//};

function Nav() {
  return (
    <Router>
    <div className="w3-top">
    <div className="w3-bar w3-card w3-left-align w3-large" style={{backgroundColor:'#ceeaff'}}>
    <button className="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-padding-large w3-large style={{backgroundColor:'#DAAD86'}}" onClick={bars} title="Toggle Navigation Menu"><i className="fa fa-bars"></i></button>
    <Link to="/" className="w3-bar-item w3-button w3-padding-large hoverable homeColor">Home</Link>
    <Link to="/professional" className="w3-bar-item w3-button w3-hide-small w3-padding-large hoverable">Professional</Link>
    <Link to="/blog" className="w3-bar-item w3-button w3-hide-small w3-padding-large hoverable">Blog</Link>
    <Link to="/sideVentures" href="/" className="w3-bar-item w3-button w3-hide-small w3-padding-large hoverable">Side Ventures</Link>
    </div>

    <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium w3-large" style={{backgroundColor:'#ceeaff'}}>
    <Link to="/professional" className="w3-bar-item w3-button w3-padding-large hoverable" onClick={barsClose}>Professional</Link>
    <Link to="/blog" className="w3-bar-item w3-button w3-padding-large hoverable" onClick={barsClose}>Blog</Link>
    <Link to="/sideVentures" className="w3-bar-item w3-button w3-padding-large hoverable" onClick={barsClose}>Side Ventures</Link>
    </div>
    </div>


    <div>
    <Route exact path="/" component={Home} />
    <Route path="/professional" component={Professional} />
    <Route path="/blog" component={Blog} />
    <Route path="/sideVentures" component={Side} />
    </div>
    </Router>
    );
}

export default Nav;

function bars() {
  var x = document.getElementById("navDemo");
  if (x.className.indexOf("w3-show") === -1) {
    x.className += " w3-show";
  } else { 
    x.className = x.className.replace(" w3-show", "");
  }
}

function barsClose() {
  var x = document.getElementById("navDemo");
  x.className = x.className.replace(" w3-show", "");
}