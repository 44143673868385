import boy from '../images/boy.jpg';
import bron from '../images/bron-bron.png';
import mike from '../images/mike.png';
import floatinghead from '../images/floatinghead.png';
import sunny from '../images/sunnyTreeTyler.png';
import night from '../images/nightTreeTyler.png';
import green from '../images/GreenTreeTyler.png';
import pastel from '../images/PantsTyler.png';
import './blog.css';

function Blog() {
  return (
    <div className="Home">
        <div className="w3-container" style={{padding:"128px 16px"}} id="team">
            <h3 className="w3-center">TYLER'S BLOG</h3>
            <p className="w3-center w3-large">This is some of my  art, hobbies, and side projects.</p>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Boy You Look Like</h3>
                    <img src={boy} className="photo" alt="Boy" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Bron Bron</h3>
                    <img src={bron} className="photo" alt="Bron" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>He Who Dances Last</h3>
                    <img src={mike} className="photo" alt="Boy" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Floating Head</h3>
                    <img src={floatinghead} className="photo" alt="Logo" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Cloud 9 | 1</h3>
                    <img src={sunny} className="photo" alt="Sunny" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Cloud 9 | 2</h3>
                    <img src={night} className="photo" alt="Night" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Cloud 9 | 3</h3>
                    <img src={green} className="photo" alt="Green" />
                </div>
            </div>

            <div className="w3-half card">
                <div className="w3-card w3-container" style={{minHeight:"460px"}}>
                    <h3>Cloud 9 | 4</h3>
                    <img src={pastel} className="photo" alt="Green" />
                </div>
            </div>

            <h3 className="w3-center" style={{paddingTop:"100px;"}}>TYLER'S LIBRARY</h3>

            <div className="book" style={{backgroundColor: "black",color:"white"}}><span className="bookTitle">White Fragility</span></div>
            <div className="book" style={{backgroundColor: "#b77b3f",color:"black"}}><span className="bookTitle">The Fire Next Time</span></div>
            <div className="book" style={{backgroundColor: "#bd463e",color:"#3b463f"}}><span className="bookTitle">Assata</span></div>
            <div className="book" style={{backgroundColor: "#932f5c",color:"white"}}><span className="bookTitle">Parable of the Sower</span></div>
            <div className="book" style={{backgroundColor: "#a0252b",backgroundImage: "linear-gradient(to right, red , yellow)"}}><span className="bookTitle">Long Walk to Freedom</span></div>
            <div className="book" style={{backgroundColor: "#7c93be",color:"black",fontFamily:'Brush Script MT'}}><span className="bookTitle">Giovanni's Room</span></div>
            <div className="book" style={{backgroundColor: "#be493e",color:"#f1da2a"}}><span className="bookTitle">Parable of the Talents</span></div>
            <div className="shelf"></div>

        </div>
    </div>
    );
}

export default Blog;