import Resume from '../images/resume.pdf';
import engResume from '../images/engineeringresume.pdf';
import './professional.css'

function Professional() {
  return (
    <div className="Home">
        <div className="w3-row-padding w3-padding-64 w3-container">
            <div>
                <h1>Professional Profile</h1>
                <p>I am currently a Professional Services Consultant at Adobe with specializations in Adobe Analytics, Adobe Launch (Tag Management), and reporting strategy.</p>
                <p>While working at Adobe, I've completed the development, launch, and support of two Adobe Launch Extensions availalbe via the Adobe Launch Extension Catalog. Both extensions revolve around video tracking and have more details below.</p>
                <p>For more information about my prior experiences or my current role, please view my <a className="a" href={Resume}  rel="noreferrer" target = "_blank">Professional Service's Consulting Résumé</a> and <a className="a" href={engResume}  rel="noreferrer" target = "_blank">Software Engineering Résumé</a>.</p>
                <br></br>
                <h1>Keeping Fresh</h1>
                <a className="a" href="https://github.com/tylerdamonwill/LeetCode"  rel="noreferrer" target = "_blank">Technical Interview Prep</a>
            </div>

        </div>
    </div>
  );
}

export default Professional;